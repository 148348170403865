'use client';

import type { Property } from 'csstype';
import React, {
	type ReactNode, useEffect, useRef, useState, RefObject
} from 'react';
import classNames from 'classnames';
import { observer } from 'mobx-react';

import styles from '~/components/flyout-menu/FlyoutMenu.module.scss';
import { useForbidMultipleOpenMenus } from '~/components/flyout-menu/useForbidMultipleOpenMenus';
import { EmailIconSvg } from '~/components/flyout-menu/EmailIcon.svg';
import { noop } from '~/util/noop';
import { sanitize } from '~/util/sanitizeString';

const variants = {
	Meatball: 'meatball',
	Email: 'email',
} as const;
export type Variant = typeof variants;
export type VariantKey = keyof Variant;

export type IconProps = {
	isSubmenuOpen?: boolean
};
/**
 * false                       - Disable auto positioning. Will use the default position on mount.
 * true                        - Auto position on.
 * 'right' | 'center' | 'left' - Force and stick to this position. Disable auto positioning.
 */
type SubmenuPosition = boolean | 'right' | 'center' | 'left';

export type MenuProps = {
	autoSubmenuPosition?: SubmenuPosition
	buttonClassName?: string
	buttonRef?: RefObject<HTMLButtonElement>
	className?: string
	children: ReactNode
	customSubmenuEdgeDetection?: (submenuRef: RefObject<HTMLDivElement>, name: string) => void
	disabled?: boolean
	focusId?: string
	IconComponent?: React.FunctionComponent<IconProps>
	screenReaderName?: string
	name: string
	onMenuOpen?: (menuRef: RefObject<HTMLDivElement>, submenuRef: RefObject<HTMLDivElement>) => void
	submenuStyles?: string | Record<string, any>
	submenuOffsetLeft?: string | null
	submenuLoaded?: boolean
	title?: string
	width?: Property.Width
	variant?: VariantKey
};

type Identifier = {
	menuDataQa: string
	menuButtonId: string
	menuButtonDataQa: string
	menuId: string
};

export const FlyoutMenu = observer(({
	autoSubmenuPosition = true,
	buttonClassName,
	buttonRef,
	className = '',
	children,
	customSubmenuEdgeDetection,
	disabled = false,
	focusId,
	IconComponent,
	screenReaderName,
	name,
	onMenuOpen,
	submenuLoaded = true,
	submenuStyles = '',
	submenuOffsetLeft = null,
	title = '',
	width = '200px',
	variant = 'Meatball',
}: MenuProps) => {
	const idPrefix = `${variants[variant]}-menu-${sanitize(name)}`;
	const menuDataQa = `${idPrefix}-container`;
	const menuButtonDataQa = `${idPrefix}-button`;
	const menuId = `${menuDataQa}`;
	const menuButtonId = `${menuButtonDataQa}`;
	const identifier = useRef<Identifier>({
		menuDataQa,
		menuButtonId,
		menuButtonDataQa,
		menuId,
	});
	const [submenuOpen, setSubmenuOpen] = useState(false);
	const [isHydrated, setIsHydrated] = useState(false);
	const defaultSubmenuPosition = autoSubmenuPosition === undefined ? 'right' : autoSubmenuPosition;
	const [submenuPosition, setSubmenuPosition] = useState<SubmenuPosition>(defaultSubmenuPosition);
	const menuRef = useRef<HTMLDivElement>(null);
	const submenuRef = useRef<HTMLDivElement>(null);
	const [registerMenu, deregisterMenu, closeAllOtherMenus] = useForbidMultipleOpenMenus();
	const [hover, setHover] = useState(false);
	const [edgeDetectionCalled, setEdgeDetectionCalled] = useState(false);
	const submenuRawStyles: Record<string, any> = { width };

	let submenuClassName = '';
	let submenuPositioningClassName = '';
	let Icon: React.FunctionComponent<IconProps>;

	Icon = () => (
		<>
			<div className={styles.meatball} />
			<div className={styles.meatball} />
			<div className={styles.meatball} />
		</>
	);

	if (submenuStyles) {
		submenuClassName = typeof submenuStyles === 'string' ? submenuStyles : submenuStyles?.submenu || '';
	} else {
		submenuClassName = styles.submenu;
	}

	if (submenuOffsetLeft !== null) {
		submenuRawStyles.left = submenuOffsetLeft;
	}
	if (typeof submenuPosition === 'string') {
		const stylesToUse = submenuStyles || styles;

		if (typeof stylesToUse === 'string') {
			submenuPositioningClassName = submenuPosition;
		} else {
			submenuPositioningClassName = stylesToUse[submenuPosition];
		}
	}

	if (variant === 'Email') {
		Icon = () => <EmailIconSvg hover={hover} />;
	}

	if (IconComponent) {
		Icon = props => <IconComponent isSubmenuOpen={props.isSubmenuOpen} />;
	}

	const documentClickHandler = (e: MouseEvent) => {
		// Only close the submenu when a button is clicked within it.
		const { target } = e;

		if (
			!(target instanceof HTMLElement || target instanceof SVGElement)
			|| !target.closest
			|| (target.closest(`#${identifier.current.menuId}`) && target.nodeName !== 'BUTTON')
			|| !submenuOpen
		) {
			return;
		}
		setSubmenuOpen(false);
	};

	const menuClickHandler = async (e: React.MouseEvent) => {
		if (identifier.current.menuId) {
			closeAllOtherMenus(identifier.current.menuId);
		}
		setSubmenuOpen(!submenuOpen);
		e.stopPropagation();
	};

	function handleIntersect(entries: IntersectionObserverEntry[]) {
		// Element is visible
		const ir = entries[0].intersectionRatio;
		if (ir === 1 || (ir > 0 && ir < 0.5)) {
			setSubmenuPosition('right');
		} else if (ir >= 0.5) {
			setSubmenuPosition('center');
		} else {
			setSubmenuPosition('left');
		}
	}

	useEffect(() => {
		if (!menuRef?.current || !autoSubmenuPosition || typeof autoSubmenuPosition === 'string') {
			return noop;
		}
		const options = {
			root: null,
			rootMargin: '0px -200px 0px 0px',
			threshold: [0, 0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1],
		};
		const obs = new IntersectionObserver(handleIntersect, options);

		obs.observe(menuRef.current);

		return () => {
			if (!menuRef.current) {
				return;
			}
			obs.unobserve(menuRef.current);
		};
	}, [menuRef.current, autoSubmenuPosition]);

	useEffect(() => {
		if (!menuRef?.current || typeof autoSubmenuPosition !== 'string') {
			return;
		}
		setSubmenuPosition(autoSubmenuPosition);
	}, [menuRef.current, autoSubmenuPosition]);

	useEffect(() => {
		// clear the form on click outside the sign in flyout
		document.addEventListener('click', documentClickHandler);
		return function () {
			document.removeEventListener('click', documentClickHandler);
		};
	}, [submenuOpen]);

	// Handle menu open with edge detection.
	useEffect(() => {
		if (!submenuOpen || !submenuRef.current || !customSubmenuEdgeDetection) {
			return;
		}
		if (!submenuLoaded && onMenuOpen) {
			onMenuOpen(menuRef, submenuRef);
			return;
		}
		if (!edgeDetectionCalled) {
			customSubmenuEdgeDetection(submenuRef, name);
		}
		setEdgeDetectionCalled(true);
	}, [onMenuOpen, submenuOpen, submenuRef, edgeDetectionCalled, submenuLoaded]);

	// Handle menu open without edge detection.
	useEffect(() => {
		if (!submenuOpen || !submenuRef.current) {
			return;
		}
		if (onMenuOpen) {
			onMenuOpen(menuRef, submenuRef);
		}
	}, [submenuOpen, submenuRef]);

	useEffect(() => {
		registerMenu({ menuId, useStateFn: setSubmenuOpen });
		setIsHydrated(true);
		return () => {
			deregisterMenu(menuId);
		};
	}, []);

	const submenuVisible = customSubmenuEdgeDetection
		? submenuOpen && (!onMenuOpen || edgeDetectionCalled)
		: submenuOpen;

	return (
		<div
			className={classNames(`${styles['flyout-menu']}`, {
				// Also need global class to account for outside overrides.
				[`${styles['menu-open']} menu-open`]: submenuOpen,
				[className]: Boolean(className)
			})}
			id={identifier.current.menuId}
			data-qa={identifier.current.menuDataQa}
			ref={menuRef}
		>
			<button
				id={identifier.current.menuButtonId}
				{...(focusId && { 'data-focus-id': focusId })}
				className={classNames(styles['flyout-menu-button'], buttonClassName)}
				data-qa={identifier.current.menuButtonDataQa}
				data-sub-menu-open={submenuOpen}
				data-is-hydrated={isHydrated}
				onClick={menuClickHandler}
				onMouseEnter={() => setHover(true)}
				onMouseLeave={() => setHover(false)}
				data-tr-link-event-track={!submenuOpen}
				ref={buttonRef}
				title={title}
				disabled={disabled}
			>
				<Icon isSubmenuOpen={submenuOpen} />
				<span className="tw-sr-only">
					{`${submenuOpen ? 'Close' : 'Open'} ${screenReaderName || name} menu`}
				</span>
			</button>
			<div
				data-submenu-for-button-id={identifier.current.menuButtonId}
				className={classNames(submenuClassName, submenuPositioningClassName, {
					'tw-visible': submenuVisible,
				})}
				ref={submenuRef}
				style={submenuRawStyles}
			>
				{children}
			</div>
		</div>
	);
});
