import React from 'react';

import { type OpenDeliverySummaryStore } from '~/engage/toolbar/open-delivery-summary/Stores/OpenDeliverySummary.store';
import { OpenDeliverySummary } from '~/engage/toolbar/open-delivery-summary/Components/OpenDeliverySummary';

export const OpenDeliverySummaryMenu = ({ store }: { store: OpenDeliverySummaryStore }) => {
	return (
		<div className="tw-space-y-4 tw-text-base eng:tw-text-sm">
			<OpenDeliverySummary store={store} />
		</div>
	);
};
