import React from 'react';
import { observer } from 'mobx-react';
import cn from 'classnames';

import { DeliveryTruckSvg } from '~/engage/toolbar/Components/DeliveryTruck.svg';
import { CheckmarkSvg } from '~/engage/toolbar/Components/Checkmark.svg';
import css from '~/engage/toolbar/Components/engage-toolbar.module.scss';

export const DeliveryTruckIcon = observer(({ isSubmenuOpen = false }: { isSubmenuOpen?: boolean }) => {
	return (
		<div className={cn('tw-relative', css.activeEffectStroke, css.activeEffectFill, {
			[css.isSubmenuOpen]: isSubmenuOpen,
		})}>
			<DeliveryTruckSvg />
			<div
				className={`
					tw-w-[18px] tw-h-[18px] tw-left-[-7px] tw-bottom-[-3px] tw-bg-white tw-absolute tw-rounded-full
					tw-border tw-border-[currentcolor]
				`}
			>
				<div
					className={`
						tw-w-[14px] tw-absolute tw-top-1/2 tw-left-1/2 tw-transform tw--translate-x-1/2
						tw--translate-y-1/2
					`}
				>
					<CheckmarkSvg />
				</div>
			</div>
		</div>
	);
});
