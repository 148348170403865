import React from 'react';

import { useGlobalContext } from '~/global/Contexts/Global.context';

export const MessageValuesCheck = ({
	expectedValueKeys = [], actualValues = {}, children, messageKey, classes, containerElement = 'LI'
}) => {
	const { featureTogglesModel } = useGlobalContext();
	const Container = containerElement;
	if (!featureTogglesModel.isOn('DEBUG_MODE')) {
		return <Container data-qa={messageKey} className={classes}>{children}</Container>;
	}

	const actualValueKeys = Object.keys(actualValues);
	const missingValues = expectedValueKeys.map((key) => {
		if (actualValueKeys.includes(key)) {
			return null;
		}
		return key;
	}).filter((el) => {
		return el != null;
	});

	return (
		<Container data-qa={messageKey} className={classes}>
			{children}
			{
				Boolean(missingValues.length) &&
				<ul>
					{
						missingValues.map((key) => {
							return (
								<li key={`missing-value-${key}`}>
									{`Missing value: "${key}" for message: "${messageKey}"`}
								</li>
							);
						})
					}
				</ul>
			}
		</Container>
	);
};
