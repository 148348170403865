import React from 'react';
import cn from 'classnames';

type Props = {
	width?: React.CSSProperties['width']
	hover?: boolean
};

export const EmailIconSvg = (props: Props) => {
	const { width = '24', hover = false } = props;
	return (
		<svg xmlns="http://www.w3.org/2000/svg" xmlSpace="preserve" width={width} viewBox="-1 -1 24 15">
			<path
				d="M0 13V0h22v13H0ZM.154 12.783l7-7.655M21.77 12.783l-6.924-7.655"
				className={cn({
					'tw-stroke-gray tw-fill-white': !hover,
					'tw-stroke-white tw-fill-gray ': hover,
				})}
				vectorEffect="non-scaling-stroke"
			/>
			<path
				d="m0 0 11 7.511L22 0"
				className={cn({
					'tw-stroke-gray tw-fill-white': !hover,
					'tw-stroke-white tw-fill-gray ': hover,
				})}
				vectorEffect="non-scaling-stroke"
			/>
		</svg>
	);
};
