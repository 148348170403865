import React from 'react';
import { observer } from 'mobx-react';
import cn from 'classnames';

import { type DesignAssociateFollowing } from '~/engage/opportunities/Models/OpportunitiesToolbar.model';
import { OpportunitySvg } from '~/engage/toolbar/Components/Opportunity.svg';
import { CheckmarkSvg } from '~/engage/toolbar/Components/Checkmark.svg';
import css from '~/engage/toolbar/Components/engage-toolbar.module.scss';

type Props = {
	designAssociateFollowing: DesignAssociateFollowing
	isSubmenuOpen?: boolean
};

export const OpportunityIcon = observer(({ designAssociateFollowing, isSubmenuOpen }: Props) => {
	return (
		<div className={cn('tw-relative', css.activeEffectFill, {
			[css.isSubmenuOpen]: isSubmenuOpen,
		})}>
			<OpportunitySvg />
			{
				designAssociateFollowing === 'SELF' &&
				<div
					className={`
						tw-w-[18px] tw-h-[18px] tw-left-[-15px] tw-bottom-[-3px] tw-bg-white tw-absolute tw-rounded-full
						tw-border tw-border-[currentcolor]
					`}
				>
					<div
						className={`
							tw-w-[14px] tw-absolute tw-top-1/2 tw-left-1/2 tw-transform tw--translate-x-1/2
							tw--translate-y-1/2
						`}
					>
						<CheckmarkSvg />
					</div>
				</div>
			}
			{
				designAssociateFollowing === 'ANOTHER' &&
				<div
					className={`
						tw-w-[18px] tw-h-[18px] tw-left-[-15px] tw-bottom-[-3px] tw-bg-[currentcolor] tw-absolute
						tw-rounded-full tw-border tw-border-white tw-flex tw-justify-center tw-items-center
					`}
				>
					<div className="tw-bg-white tw-h-[2px] tw-w-[10px]" />
				</div>
			}
		</div>
	);
});
