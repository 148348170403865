'use client';

import { observer } from 'mobx-react';
import React from 'react';

import { MagicModal } from '~/components/magic-modal/Components/MagicModal';
import { LiveChat } from '~/global/live-chat/Components/LiveChat';
import { AdobeTracking } from '~/lib/scripts/AdobeTracking';
import { ReflektionScripts } from '~/lib/reflektion/ReflektionScripts';
import { PublicGlobals } from '~/lib/scripts/PublicGlobals';
import { NewRelic } from '~/lib/scripts/NewRelic';
import { HumanSensor } from '~/lib/scripts/HumanSensor';
import { useGlobalResets } from '~/hooks/useGlobalResets';
import { isEngage } from '~/global/global.constants';
import { useLinkEvent } from '~/hooks/useLinkEvent';
import { noop } from '~/util/noop';
import { PageTransition } from '~/global/PageTransition';

const ClientComponentsForLayout = () => {
	const {
		autoCloseHeaderMobileAccountMenu = noop,
		autoCloseHeaderMobileSearch = noop,
		autoCloseModal = noop,
	} = useGlobalResets();

	if (!isEngage) {
		useLinkEvent();
	}

	autoCloseHeaderMobileAccountMenu();
	autoCloseHeaderMobileSearch();
	autoCloseModal();

	return (
		<>
			<PageTransition />
			<MagicModal />
			<LiveChat />

			{/* third party scripts */}
			<AdobeTracking />
			<ReflektionScripts />
			<PublicGlobals />
			<NewRelic />
			<HumanSensor />
		</>
	);
};

ClientComponentsForLayout.displayName = 'ClientComponentsForLayout';

const ClientComponentsForLayoutObserver = observer(ClientComponentsForLayout);

export { ClientComponentsForLayoutObserver as ClientComponentsForLayout };
