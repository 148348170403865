import React from 'react';

export const OpportunitySvg = () => {
	// 20x20
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width="19" viewBox="0 0 20 21.36">
			<path
				d="M12.78 2.86 12.3.51.5.81v20l3.4-.3v-8.24h6.72l.48 2.35h8.4V2.86h-6.72Z"
				vectorEffect="non-scaling-stroke"
			/>
		</svg>
	);
};
