import React from 'react';
import Link from 'next/link';

import { type CreateAccountOptions, openSetUpOnlineAccessModal } from '~/engage/toolbar/set-up-online-access/openSetUpOnlineAccessModal';
import { useGlobalContext } from '~/global/Contexts/Global.context';

export const OnlineAccessMenu = ({ hasAccess }: { hasAccess?: boolean }) => {
	const {
		magicModal,
		globalDynamicStore,
		HREF,
	} = useGlobalContext();

	return (
		<div className="tw-space-y-4 tw-text-base eng:tw-text-sm">
			<div>
				<div className="tw-font-bold">Online Access</div>
				{
					hasAccess
						?
						<div>This customer has online access.</div>
						:
						<div>This customer does not currently have online access.</div>
				}
			</div>
			{
				hasAccess
					?
					<div>
						<Link className="tw-text-base eng:tw-text-sm" href={HREF.account.overview}>
							Go to Account
						</Link>
					</div>
					:
					<div>
						<button
							className="ButtonAnchor tw-text-base eng:tw-text-sm"
							data-qa="set-up-online-access-button"
							type="button"
							onClick={() => {
								const options: Partial<CreateAccountOptions> = {
									modalOptions: {
										onCloseFocusElement: '#meatball-menu-online-access-button',
									},
								};

								openSetUpOnlineAccessModal(magicModal, globalDynamicStore, options);
							}}
						>
							Set up online access
						</button>
					</div>
			}
		</div>
	);
};
