import React from 'react';
import { observer } from 'mobx-react';
import cn from 'classnames';

import { type ActiveEffect } from '~/engage/toolbar/Components/EngageToolbarMenuItem';
import { FlyoutMenu, type MenuProps } from '~/components/flyout-menu/FlyoutMenu';
import css from '~/engage/toolbar/Components/engage-toolbar.module.scss';
import flyoutCss from '~/components/flyout-menu/FlyoutMenu.module.scss';

type Props = {
	className?: string
	children: React.ReactNode
	flyoutMenuProps: Omit<MenuProps, 'children'>
	activeEffect?: ActiveEffect
	paddingClassName: string
};

export const EngageToolbarFlyoutMenuItem = observer(({
	paddingClassName, flyoutMenuProps, children, activeEffect = 'ALL',
}: Props) => {
	const menuProps: Omit<MenuProps, 'children'> = {
		className: cn(css.flyoutMenuItem),
		buttonClassName: cn(flyoutCss.noOutline, flyoutCss.noShadow, css.flyoutMenuButton, paddingClassName, 'tw-group', {
			[css.activeEffectFill]: activeEffect === 'FILL' || activeEffect === 'ALL',
			[css.activeEffectStroke]: activeEffect === 'STROKE' || activeEffect === 'ALL',
		}),
		submenuStyles: css,
		autoSubmenuPosition: 'right',
		width: 'max-content',
		...flyoutMenuProps
	};

	return (
		<FlyoutMenu {...menuProps}>
			{children}
		</FlyoutMenu>
	);
});
