'use client';

import React, { useEffect, useState, useTransition } from 'react';
import { observer } from 'mobx-react';
import Link from 'next/link';
import cn from 'classnames';

import { type MenuViewKey } from '~/engage/toolbar/Stores/EngageToolbar.store';
import { type ToolbarMode } from '~/layout/Components/toolbar/EngageToolbarWrapper';
import { SideMenu } from '~/engage/toolbar/side-menu/Components/SideMenu';
import { EngageToolbarMenuItem } from '~/engage/toolbar/Components/EngageToolbarMenuItem';
import { EngageToolbarFlyoutMenuItem } from '~/engage/toolbar/Components/EngageToolbarFlyoutMenuItem';
import { QuickLinksIcon } from '~/engage/toolbar/Components/QuickLinksIcon';
import { ShoppingTagIcon } from '~/engage/toolbar/Components/ShoppingTagIcon';
import { OnlineAccessIcon } from '~/engage/toolbar/Components/OnlineAccessIcon';
import { FlyoutMenu } from '~/components/flyout-menu/FlyoutMenu';
import { OpportunityIcon } from '~/engage/toolbar/Components/OpportunityIcon';
import { DeliveryTruckIcon } from '~/engage/toolbar/Components/DeliveryTruckIcon';
import { ProfileIcon } from '~/engage/toolbar/Components/ProfileIcon';
import { BusinessCustomerIcon } from '~/engage/toolbar/Components/BusinessCustomerIcon';
import { OnlineAccessMenu } from '~/engage/toolbar/Components/OnlineAccessMenu';
import { useGlobalContext } from '~/global/Contexts/Global.context';
import { useEngageToolbar } from '~/engage/toolbar/Contexts/EngageToolbar.context';
import { EngageCustomersIcon } from '~/engage/toolbar/Components/EngageCustomersIcon';
import { Overlay } from '~/engage/toolbar/Components/Overlay';
import { OpportunityMenu } from '~/engage/toolbar/Components/OpportunityMenu';
import { ProfileMenu } from '~/engage/toolbar/Components/ProfileMenu';
import { OpenDeliverySummaryMenu } from '~/engage/toolbar/Components/OpenDeliverySummaryMenu';
import css from '~/engage/toolbar/Components/engage-toolbar.module.scss';
import flyoutCss from '~/components/flyout-menu/FlyoutMenu.module.scss';
import { PATHNAME, SLUG } from '~/checkout/Types/constants';

export type ActiveMenuStates = {
	[key in MenuViewKey]: boolean
};

export const EngageToolbar = observer(function EngageToolbar({ mode }: { mode: ToolbarMode }) {
	const toolbarStore = useEngageToolbar();
	const [isPending] = useTransition();
	const {
		globalDynamicModel,
		globalDynamicModel: {
			createAccountLink,
			locationSelectorLink,
			myOpportunity,
			openDeliveryGroupsLink,
			openDeliveryGroupsStatusLink,
		},
		globalDynamicStore: {
			hasInitiallyLoaded,
		},
	} = useGlobalContext();
	const {
		incognitoLocation,
		openDeliverySummary,
		opportunities,
		opportunities: {
			openReassignOpportunityModal,
			toolbarModel: {
				createNewOpportunityLink,
				dateCreated = '',
				designAssociateFollowing = null,
				employeeName,
			} = {},
		},
	} = toolbarStore;
	const showOpportunityMenuItem = designAssociateFollowing || createNewOpportunityLink;
	const [overflowHidden, setOverflowHidden] = useState(true);
	const [showAllMenuItems, setShowAllMenuItems] = useState(true);
	const [hasOnlineAccess, setHasOnlineAccess] = useState(false);
	const [workingWithName, setWorkingWithName] = useState('');
	const [isBusinessCustomer, setIsBusinessCustomer] = useState('');
	const [incognitoMode, setIncognitoMode] = useState(false);
	const [logoutLink, setLogoutLink] = useState<string | null>(null);
	const [employeeDisplayName, setEmployeeDisplayName] = useState('');
	const [employeeInitials, setEmployeeInitials] = useState('');
	const [storeName, setStoreName] = useState('');
	const [showOpenDeliveryGroupsMenuItem, setShowOpenDeliveryGroupsMenuItem] = useState(false);
	const [sideMenuActive, setSideMenuActive] = useState<ActiveMenuStates>({
		CUSTOMERS: false,
		QUICK_LINKS: false,
		SHOP: false,
	});

	useEffect(() => {
		if (isPending) {
			toolbarStore.closeMenu();
		}
	}, [isPending]);

	useEffect(() => {
		const { pathname } = new URL(window.location.href);
		const isOnOrderConfirmationPage = pathname.endsWith(SLUG.ORDER_CONFIRMATION);
		const isOnCheckout = Object.values(PATHNAME).some(item => pathname.startsWith(item));

		setShowAllMenuItems(!isOnCheckout || isOnOrderConfirmationPage);
	}, []);

	useEffect(() => {
		if (!hasInitiallyLoaded) {
			return;
		}
		setHasOnlineAccess(!createAccountLink);
	}, [createAccountLink, hasInitiallyLoaded]);

	useEffect(() => {
		if (!hasInitiallyLoaded) {
			return;
		}
		setWorkingWithName(globalDynamicModel.workingWithName);
	}, [globalDynamicModel.workingWithName, hasInitiallyLoaded]);

	useEffect(() => {
		if (
			!hasInitiallyLoaded
		) {
			return;
		}
		setIsBusinessCustomer(globalDynamicModel.isBusinessCustomer);
	}, [globalDynamicModel.isBusinessCustomer, hasInitiallyLoaded]);

	useEffect(() => {
		if (!hasInitiallyLoaded) {
			return;
		}
		opportunities.updateToolbar(myOpportunity);
	}, [myOpportunity, hasInitiallyLoaded]);

	useEffect(() => {
		if (!hasInitiallyLoaded) {
			return;
		}
		setIncognitoMode(Boolean(locationSelectorLink));
	}, [locationSelectorLink, hasInitiallyLoaded]);

	useEffect(() => {
		if (!hasInitiallyLoaded) {
			return;
		}
		setLogoutLink(globalDynamicModel.logoutLink);
	}, [globalDynamicModel.logoutLink, hasInitiallyLoaded]);

	useEffect(() => {
		if (!hasInitiallyLoaded) {
			return;
		}
		setEmployeeDisplayName(globalDynamicModel.employeeDisplayName);
	}, [globalDynamicModel.employeeDisplayName, hasInitiallyLoaded]);

	useEffect(() => {
		if (!hasInitiallyLoaded) {
			return;
		}
		setEmployeeInitials(globalDynamicModel.employeeInitials);
	}, [globalDynamicModel.employeeInitials, hasInitiallyLoaded]);

	useEffect(() => {
		if (!hasInitiallyLoaded) {
			return;
		}
		setStoreName(globalDynamicModel.storeName);
	}, [globalDynamicModel.storeName, hasInitiallyLoaded]);

	useEffect(() => {
		if (!hasInitiallyLoaded || !openDeliverySummary) {
			return;
		}
		openDeliverySummary.model.openDeliveryGroupsStatusLink = openDeliveryGroupsStatusLink;
		if (openDeliverySummary.model.showTruckIcon === undefined && openDeliveryGroupsStatusLink) {
			openDeliverySummary.initStatus();
		} else {
			openDeliverySummary.model.setShowTruckIcon(Boolean(
				openDeliveryGroupsStatusLink
				&& openDeliverySummary.model.showTruckIcon
			));
		}
		const showMenuItem = Boolean(openDeliverySummary.model.showTruckIcon);

		setShowOpenDeliveryGroupsMenuItem(showMenuItem);
	}, [openDeliveryGroupsStatusLink, hasInitiallyLoaded, openDeliverySummary?.model.showTruckIcon]);

	useEffect(() => {
		toolbarStore.setActiveSideMenuState = setSideMenuActive;
	}, []);

	if (mode === 'SIMPLE') {
		return (
			<div className={`${css.engageToolbar} ${css.simple}`}>
				<div className="tw-line-clamp-2 tw-break-all">{workingWithName}</div>
			</div>
		);
	}
	return <>
		<nav className={css.engageToolbar} data-qa="engage-toolbar">
			<ul className="tw-flex tw-h-full tw-m-0">
				<li className="tw-flex-shrink-0">
					<EngageToolbarMenuItem
						name="CUSTOMERS"
						paddingX="1.367rem"
						Icon={EngageCustomersIcon}
						onClick={() => {
							setSideMenuActive((prevState) => {
								return {
									CUSTOMERS: !prevState.CUSTOMERS,
									SHOP: false,
									QUICK_LINKS: false,
								};
							});
							toolbarStore.toggleMenu('CUSTOMERS');
						}}
						open={sideMenuActive.CUSTOMERS}
					/>
				</li>
				<li className="tw-flex-shrink-0">
					<EngageToolbarMenuItem
						name="QUICK_LINKS"
						paddingX="1.5rem"
						Icon={QuickLinksIcon}
						onClick={() => {
							setSideMenuActive((prevState) => {
								return {
									CUSTOMERS: false,
									SHOP: false,
									QUICK_LINKS: !prevState.QUICK_LINKS,
								};
							});
							toolbarStore.toggleMenu('QUICK_LINKS');
						}}
						open={sideMenuActive.QUICK_LINKS}
					/>
				</li>
				<li className="tw-flex-shrink-0 tw-mr-0 eng:tw-mr-1">
					<EngageToolbarMenuItem
						name="SHOP"
						paddingX="1.434rem"
						Icon={ShoppingTagIcon}
						onClick={() => {
							setSideMenuActive((prevState) => {
								return {
									CUSTOMERS: false,
									SHOP: !prevState.SHOP,
									QUICK_LINKS: false,
								};
							});
							toolbarStore.toggleMenu('SHOP');
						}}
						open={sideMenuActive.SHOP}
					/>
				</li>
			</ul>
			<ul className={`
				${css.divider} ${css.dividerLeft} ${css.dividerRight}
				tw-flex eng:tw-justify-center tw-h-full tw-m-0 tw-flex-grow
				before:tw-mr-5 before:tw-self-stretch
				 after:tw-mr-5  after:tw-self-stretch
			`}>
				{
					Boolean(workingWithName) &&
					<li className="tw-self-center tw-pr-1 eng:tw-flex-grow-0">
						<div className="tw-flex">
							{
								Boolean(isBusinessCustomer) &&
								<BusinessCustomerIcon color="white" className="tw-mr-2" width="20" />
							}
							<Link
								href="/account/overview"
								data-qa="working-with-link"
								className={`
									tw-block tw-uppercase tw-text-gold tw-font-bold
									focus:tw-text-white hover:tw-text-white
									!tw-outline-white !tw-shadow-none tw-select-none
								`}
								title={workingWithName}
							>
								<div className="tw-line-clamp-2 tw-break-all">
									{workingWithName}
								</div>
							</Link>
						</div>
					</li>
				}
				{
					showAllMenuItems &&
					<li className={cn('tw-flex-shrink-0', { 'tw-overflow-hidden': overflowHidden })}>
						<EngageToolbarFlyoutMenuItem
							flyoutMenuProps={{
								screenReaderName: 'online access',
								name: 'online-access',
								IconComponent: props => (
									<OnlineAccessIcon
										hasAccess={hasOnlineAccess}
										isSubmenuOpen={props.isSubmenuOpen}
									/>
								),
								submenuOffsetLeft: toolbarStore.onlineAccessSubmenuOffsetLeft,
								customSubmenuEdgeDetection: (submenuRef, name) => {
									setOverflowHidden(false);
									toolbarStore.edgeDetection(submenuRef, name);
								},
								onMenuOpen: () => {
									toolbarStore.closeMenu();
								},
							}}
							paddingClassName="tw-px-[1.534rem]"
							activeEffect="FILL"
						>
							<OnlineAccessMenu hasAccess={hasOnlineAccess} />
						</EngageToolbarFlyoutMenuItem>
					</li>
				}
				{
					showAllMenuItems && showOpportunityMenuItem &&
					<li className="tw-flex-shrink-0">
						<EngageToolbarFlyoutMenuItem
							flyoutMenuProps={{
								name: 'opportunities',
								IconComponent: props => (
									<OpportunityIcon
										designAssociateFollowing={designAssociateFollowing}
										isSubmenuOpen={props.isSubmenuOpen}
									/>
								),
								onMenuOpen: () => {
									toolbarStore.closeMenu();
								},
							}}
							paddingClassName="tw-px-[1.567rem]"
							activeEffect="FILL"
						>
							<OpportunityMenu
								createNewOpportunity={
									createNewOpportunityLink
										?
										() => {
											opportunities.createNewOpportunity(createNewOpportunityLink);
										}
										:
										undefined
								}
								dateCreated={dateCreated}
								designAssociateFollowing={designAssociateFollowing}
								employeeName={employeeName}
								reassignOpportunity={openReassignOpportunityModal.bind(opportunities)}
							/>
						</EngageToolbarFlyoutMenuItem>
					</li>
				}
				{
					showOpenDeliveryGroupsMenuItem && openDeliverySummary !== undefined &&
					<li className="tw-flex-shrink-0">
						<EngageToolbarFlyoutMenuItem
							flyoutMenuProps={{
								screenReaderName: 'delivery summary',
								name: 'delivery-summary',
								IconComponent: props => <DeliveryTruckIcon isSubmenuOpen={props.isSubmenuOpen} />,
								onMenuOpen: () => {
									toolbarStore.onOpenDeliverySummaryMenuOpen(openDeliveryGroupsLink);
									toolbarStore.closeMenu();
								},
								// Suppress adding an inline style. This is handled on the CSS itself.
								width: '',
							}}
							paddingClassName="tw-px-[0.9rem]"
						>
							<OpenDeliverySummaryMenu store={openDeliverySummary} />
						</EngageToolbarFlyoutMenuItem>
					</li>
				}
				<li aria-hidden className="tw-flex-grow eng:tw-hidden" />
			</ul>
			<ul className="tw-flex tw-h-full tw-m-0 tw-items-center tw-flex-shrink-0 tw-gap-5 tw-pr-5">
				<li className="tw-flex-shrink-0">
					<button
						data-qa="shop-with-new-guest-button"
						className={css.newGuest}
						disabled={toolbarStore.newGuestButtonDisabled}
						onClick={toolbarStore.shopWithNewGuest}
					>
						New Guest
					</button>
				</li>
				<li className="tw-flex-shrink-0">
					<FlyoutMenu
						name="profile"
						className="tw-text-white"
						buttonClassName={
							`${flyoutCss.noOutline} ${flyoutCss.noShadow} ${css.circularFlyoutMenuButton} tw-group`
						}
						submenuStyles={css}
						IconComponent={props => (
							<ProfileIcon
								initials={employeeInitials}
								isSubmenuOpen={props.isSubmenuOpen}
							/>
						)}
						onMenuOpen={() => {
							toolbarStore.onProfileMenuOpen(locationSelectorLink, storeName);
							toolbarStore.closeMenu();
						}}
						autoSubmenuPosition="left"
						width="max-content"
					>
						<ProfileMenu
							incognitoMode={incognitoMode}
							incognitoLocation={incognitoLocation}
							logout={logoutLink === null ? undefined : () => toolbarStore.logout(logoutLink)}
							name={employeeDisplayName}
							storeName={storeName}
						/>
					</FlyoutMenu>
				</li>
			</ul>
		</nav>
		<Overlay toolbarStore={toolbarStore} />
		<SideMenu hasActiveSideMenu={toolbarStore.hasActiveSideMenu} viewState={toolbarStore.viewState} />
	</>;
});
