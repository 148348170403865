import React, { useRef } from 'react';
import { CSSTransition } from 'react-transition-group';
import { observer } from 'mobx-react';

import { type ViewStateStore } from '~/util/viewState/Stores/ViewState.store';
import { ViewState } from '~/util/viewState/Components/ViewState';

import styles from '~/engage/toolbar/side-menu/Components/side-menu.module.scss';

type Props = {
	hasActiveSideMenu: boolean
	viewState: ViewStateStore
};

export const SideMenu = observer(({ hasActiveSideMenu, viewState }: Props) => {
	const sideMenuRef = useRef(null);
	return (
		<CSSTransition
			timeout={{
				enter: 0,
				exit: 500
			}}
			in={hasActiveSideMenu}
			component="div"
			classNames={{
				enterDone: styles['oe-menu-enter-done'],
				exit: styles['oe-menu-exit'],
				exitActive: styles['oe-menu-exit'],
			}}
			nodeRef={sideMenuRef}
		>
			<div className={`${styles['oe-side-menu']} engage-side-menu`} ref={sideMenuRef}>
				<ViewState viewState={viewState} />
			</div>
		</CSSTransition>
	);
});
