import React from 'react';
import { observer } from 'mobx-react';

import { type OpenDeliverySummaryStore } from '~/engage/toolbar/open-delivery-summary/Stores/OpenDeliverySummary.store';
import { InHomeHeaders } from '~/util/orders/messaging/delivery-group-headers/Components/InHomeHeaders';
import { MagicOverlay } from '~/components/magic-overlay/Components/MagicOverlay';
import { useGlobalContext } from '~/global/Contexts/Global.context';
import css from '~/engage/toolbar/open-delivery-summary/Components/OpenDeliverySummary.module.scss';
import { s7ContentPath } from '~/global/global.constants';

export const OpenDeliverySummary = observer(({ store }: { store: OpenDeliverySummaryStore }) => {
	const { featureTogglesModel } = useGlobalContext();
	const {
		magicOverlayStore,
		model: {
			openDeliveryGroups = [],
		} = {},
	} = store;
	const result: any[] = [];

	openDeliveryGroups.forEach((openDeliveryGroup: any) => {
		const {
			orderPage: {
				href = '',
				title = '',
			} = {},
			statusMessage,
			statusMessage: {
				messageValues = {},
			} = {},
		} = openDeliveryGroup;
		const messageKey = statusMessage.messageKey as keyof typeof InHomeHeaders;

		if (!title || !messageKey || !InHomeHeaders[messageKey]) {
			return;
		}
		const Message = InHomeHeaders[messageKey];

		result.push(
			<div key={`${title}-placed-order`}>
				<a className="tw-font-normal tw-text-blue tw-tabular-nums" href={href}>{title}</a>
			</div>,
			<div key={`${title}status-message`}>
				<Message
					keyClassName="tw-font-normal tw-normal-case hidden"
					valueClassName="tw-text-gray tw-font-normal tw-normal-case"
					values={messageValues}
				/>
				{
					featureTogglesModel.isOn('DEBUG_MODE') &&
					<span>
						Some really long-winded content that is super long and obnoxiously large. Whew, I need a nap
					</span>
				}
			</div>,
		);
	});

	return (
		<div className={`${css.openDeliverySummaryContainer} tw-w-full`}>
			<MagicOverlay store={magicOverlayStore} />
			<div className="tw-grid tw-grid-cols-12 tw-gap-5 tw-items-center tw-mb-2 tw-whitespace-nowrap">
				<h1 className="tw-col-span-9 tw-font-bold">
					Upcoming Deliveries
				</h1>
				<div className="tw-col-span-3 tw-justify-self-end">
					<button
						type="button"
						className="tw-cursor-pointer"
						onClick={() => {
							if (!magicOverlayStore) {
								return;
							}
							magicOverlayStore.model.position = '30px';
							magicOverlayStore.startLoading(store.refreshOpenDeliveryGroups());
						}}
					>
						<img src={`${s7ContentPath}/icon_refresh-outline`} alt="Refresh upcoming deliveries" width="24" height="24" />
					</button>
				</div>
			</div>
			<div className={css.openDeliverySummaryBody}>
				{result}
			</div>
		</div>
	);
});
