import React, { type MouseEventHandler } from 'react';
import { observer } from 'mobx-react';

import { type DesignAssociateFollowing } from '~/engage/opportunities/Models/OpportunitiesToolbar.model';

type Props = {
	createNewOpportunity?: MouseEventHandler<HTMLButtonElement>
	dateCreated?: string
	designAssociateFollowing: DesignAssociateFollowing
	employeeName?: string
	reassignOpportunity?: MouseEventHandler<HTMLButtonElement>
};

export const OpportunityMenu = observer(({
	createNewOpportunity, designAssociateFollowing, dateCreated, employeeName, reassignOpportunity,
}: Props) => {
	let dateCreatedFragment = null;

	if (dateCreated) {
		dateCreatedFragment = (
			<div
				className="tw-font-normal tw-text-gray-300 tw-text-2xs"
				data-qa="opportunity-created-date"
			>
				Created {dateCreated}
			</div>
		);
	}
	return (
		<div className="tw-space-y-4 tw-text-base eng:tw-text-sm" data-qa="opportunity-summary">
			<div>
				<div className="tw-font-bold">Opportunity</div>
				{
					!designAssociateFollowing &&
					<div>No one is following this opportunity.</div>
				}
				{
					designAssociateFollowing === 'SELF' &&
					<>
						<div>You are following this opportunity.</div>
						{dateCreatedFragment}
					</>
				}
				{
					designAssociateFollowing === 'ANOTHER' &&
					<>
						<span className="tw-break-all tw-break-words">
							{employeeName ?? 'Someone else'}
						</span>
						<span className="tw-font-normal"> is following this opportunity.</span>
						{dateCreatedFragment}
					</>
				}
			</div>
			{
				!designAssociateFollowing && createNewOpportunity !== undefined &&
				<div>
					<button
						className="ButtonAnchor tw-text-base eng:tw-text-sm"
						type="button"
						data-qa="assign-this-opportunity-to-me-button"
						onClick={createNewOpportunity}
					>
						Add to My Opportunities
					</button>
				</div>
			}
			{
				designAssociateFollowing === 'SELF' &&
				<div>
					<a data-qa="view-my-opportunities-link" href="/opportunities">View My Opportunities</a>
				</div>
			}
			{
				designAssociateFollowing === 'ANOTHER' && reassignOpportunity !== undefined &&
				<div>
					<button
						className="ButtonAnchor tw-text-base eng:tw-text-sm"
						type="button"
						data-qa="assign-this-opportunity-to-me-button"
						onClick={reassignOpportunity}
					>
						Assign this opportunity to me
					</button>
				</div>
			}
		</div>
	);
});
