import React from 'react';
import cn from 'classnames';

import { MessageValuesCheck } from '~/util/orders/messaging/Components/MessageValuesCheck';

export const InHomeHeaders = {
	INHOME_HEADER_270: ({
		className = '',
		keyClassName = '',
		valueClassName = 'tw-text-orange',
	}) => {
		return (
			<span className={cn({ [className]: className })} data-qa="INHOME_HEADER_270">
				<span className={cn({ [keyClassName]: keyClassName })}>
					Full-service Delivery
				</span>
				<span className={cn({ [valueClassName]: valueClassName })}>Order Received</span>
			</span>
		);
	},
	INHOME_HEADER_280: ({
		className = '',
		keyClassName = '',
		valueClassName = 'tw-text-orange',
	}) => {
		return (
			<span data-qa="INHOME_HEADER_280" className={cn({ [className]: className })}>
				<span className={cn({ [keyClassName]: keyClassName })}>
					Full-service Delivery
				</span>
				<span className={cn({ [valueClassName]: valueClassName })}>Ready to Schedule</span>
			</span>
		);
	},
	INHOME_HEADER_290: ({
		className = '',
		keyClassName = '',
		valueClassName = 'tw-text-orange',
	}) => {
		return (
			<span data-qa="INHOME_HEADER_290" className={cn({ [className]: className })}>
				<span className={cn({ [keyClassName]: keyClassName })}>
					Full-service Delivery
				</span>
				<span className={cn({ [valueClassName]: valueClassName })}>Ready to Schedule, Balance Due</span>
			</span>
		);
	},
	INHOME_HEADER_300: ({
		className = '',
		keyClassName = '',
		valueClassName = 'tw-text-orange',
	}) => {
		return (
			<span data-qa="INHOME_HEADER_300" className={cn({ [className]: className })}>
				<span className={cn({ [keyClassName]: keyClassName })}>
					Full-service Delivery
				</span>
				<span className={cn({ [valueClassName]: valueClassName })}>Deferred Delivery Requested</span>
			</span>
		);
	},
	INHOME_HEADER_310: ({
		className = '',
		keyClassName = '',
		valueClassName = 'tw-text-orange',
	}) => {
		return (
			<span data-qa="INHOME_HEADER_310" className={cn({ [className]: className })}>
				<span className={cn({ [keyClassName]: keyClassName })}>
					Full-service Delivery
				</span>
				<span className={cn({ [valueClassName]: valueClassName })}>Showroom Item, Ready to Schedule</span>
			</span>
		);
	},
	INHOME_HEADER_320: ({
		values,
		values: { scheduledDate },
		className = '',
		keyClassName = '',
		valueClassName = 'tw-text-orange',
	}) => {
		return (
			<MessageValuesCheck
				messageKey="INHOME_HEADER_320"
				expectedValueKeys={['scheduledDate']}
				actualValues={values}
				containerElement="span"
			>
				<span className={cn({ [className]: className })}>
					<span className={cn({ [keyClassName]: keyClassName })}>
						Full-service Delivery
					</span>
					<span className={cn({ [valueClassName]: valueClassName })}>Scheduled for {scheduledDate}</span>
				</span>
			</MessageValuesCheck>
		);
	},
	INHOME_HEADER_330: ({
		values,
		values: { deliveryDateLong },
		className = '',
		keyClassName = '',
		valueClassName = 'tw-text-orange',
	}) => {
		return (
			<MessageValuesCheck
				messageKey="INHOME_HEADER_330"
				expectedValueKeys={['deliveryDateLong']}
				actualValues={values}
				containerElement="span"
			>
				<span className={cn({ [className]: className })}>
					<span className={cn({ [keyClassName]: keyClassName })}>
						Full-service Delivery
					</span>
					<span className={cn({ [valueClassName]: valueClassName })}>Preparing for Delivery on {deliveryDateLong}</span>
				</span>
			</MessageValuesCheck>
		);
	},
	INHOME_HEADER_340: ({
		values,
		values: { windowStartDateLong, windowEndDateLong },
		className = '',
		keyClassName = '',
		valueClassName = 'tw-text-orange',
	}) => {
		return (
			<MessageValuesCheck
				messageKey="INHOME_HEADER_340"
				expectedValueKeys={['windowStartDateLong', 'windowEndDateLong']}
				actualValues={values}
				containerElement="span"
			>
				<span className={cn({ [className]: className })}>
					<span className={cn({ [keyClassName]: keyClassName })}>
						Full-service Delivery
					</span>
					<span className={cn({ [valueClassName]: valueClassName })}>Preparing for Delivery on {windowStartDateLong} - {windowEndDateLong}</span>
				</span>
			</MessageValuesCheck>
		);
	},
	INHOME_HEADER_350: ({
		className = '',
		keyClassName = '',
	}) => {
		return (
			<span data-qa="INHOME_HEADER_350" className={cn({ [className]: className })}>
				<span className={cn({ [keyClassName]: keyClassName })}>
					Full-service Delivery
				</span>
			</span>
		);
	},
	INHOME_HEADER_550: ({
		values,
		values: { windowStartDate, windowEndDate },
		className = '',
		keyClassName = '',
		valueClassName = 'tw-text-orange',
	}) => {
		return (
			<MessageValuesCheck
				messageKey="INHOME_HEADER_550"
				expectedValueKeys={['windowStartDate', 'windowEndDate']}
				actualValues={values}
				containerElement="span"
			>
				<span className={cn({ [className]: className })}>
					<span className={cn({ [keyClassName]: keyClassName })}>
						Full-service Delivery
					</span>
					<span className={cn({ [valueClassName]: valueClassName })}>Scheduled for {windowStartDate} - {windowEndDate}</span>
				</span>
			</MessageValuesCheck>
		);
	},
	INHOME_HEADER_560: ({
		values,
		values: { completedDate },
		className = '',
		keyClassName = '',
		valueClassName = 'tw-text-orange',
	}) => {
		return (
			<MessageValuesCheck
				messageKey="INHOME_HEADER_560"
				expectedValueKeys={['completedDate']}
				actualValues={values}
				containerElement="span"
			>
				<span className={cn({ [className]: className })}>
					<span className={cn({ [keyClassName]: keyClassName })}>
						Full-service Delivery
					</span>
					<span className={cn({ [valueClassName]: valueClassName })}>Completed on {completedDate}</span>
				</span>
			</MessageValuesCheck>
		);
	},
};
