import React from 'react';
import { observer } from 'mobx-react';
import cn from 'classnames';

export const EngageCustomersIcon = observer(({ isSubmenuOpen }: { isSubmenuOpen?: boolean }) => {
	const style: React.CSSProperties = {
		strokeWidth: 1,
		strokeLinecap: 'butt',
		strokeLinejoin: 'miter',
	};
	const className = cn({
		'tw-stroke-white tw-fill-transparent group-focus:tw-fill-white group-hover:tw-fill-white': !isSubmenuOpen,
		'tw-stroke-navy-100 tw-fill-white group-hover:tw-fill-navy-100': isSubmenuOpen,
	});
	const className2 = cn({
		[`
			tw-stroke-white tw-fill-transparent
			group-focus:tw-fill-white group-focus:tw-stroke-navy-100
			group-hover:tw-fill-white group-hover:tw-stroke-navy-100
		`]: !isSubmenuOpen,
		'tw-stroke-navy-100 tw-fill-white group-hover:tw-fill-navy-100 group-hover:tw-stroke-white': isSubmenuOpen,
	});

	return (
		<svg xmlns="http://www.w3.org/2000/svg" width="26" viewBox="-1 -1 26 21">
			<path
				d="M9.5 0C11.432 0 13 1.568 13 3.5a3.502 3.502 0 0 1-5.015 3.156A4.003 4.003 0 0 0 6 3.536V3.5C6 1.568 7.568 0 9.5 0z"
				style={style}
				transform="translate(8)"
				vectorEffect="non-scaling-stroke"
				className={className}
			/>
			<path
				d="M7 3.5v.036a4.003 4.003 0 0 0-1.985 3.12A3.502 3.502 0 0 1 0 3.5C0 1.568 1.568 0 3.5 0S7 1.568 7 3.5z"
				style={style}
				transform="translate(3)"
				vectorEffect="non-scaling-stroke"
				className={className}
			/>
			<path
				d="M6 6h2.536A4 4 0 0 0 12 8v1a6.992 6.992 0 0 0-5.745 3H0a6 6 0 0 1 6-6z"
				style={style}
				transform="translate(0 3)"
				vectorEffect="non-scaling-stroke"
				className={className}
			/>
			<path
				d="M19 12h-6.255A6.992 6.992 0 0 0 7 9V8a4 4 0 0 0 3.464-2H13a6 6 0 0 1 6 6z"
				style={style}
				transform="translate(5 3)"
				vectorEffect="non-scaling-stroke"
				className={className}
			/>
			<path
				d="M12 3c2.208 0 4 1.792 4 4s-1.792 4-4 4-4-1.792-4-4 1.792-4 4-4ZM5 19a7 7 0 0 1 14 0H5Z"
				style={style}
				vectorEffect="non-scaling-stroke"
				className={className2}
			/>
		</svg>
	);
});
