import React, { useRef } from 'react';
import { observer } from 'mobx-react';
import { CSSTransition } from 'react-transition-group';

import { type EngageToolbarStore } from '~/engage/toolbar/Stores/EngageToolbar.store';
import css from '~/engage/toolbar/Components/Overlay.module.scss';

export const Overlay = observer(({ toolbarStore }: { toolbarStore: EngageToolbarStore }) => {
	const overlayRef = useRef<HTMLDivElement>(null);

	return (
		<CSSTransition
			timeout={200}
			in={Boolean(toolbarStore.activeSideMenu)}
			classNames={{
				enter: css.engageMenuOverlayEnter,
				enterActive: css.engageMenuOverlayEnterActive,
				enterDone: css.engageMenuOverlayEnterDone,
				exit: css.engageMenuOverlayLeave,
				exitActive: css.engageMenuOverlayLeaveActive,
			}}
			nodeRef={overlayRef}
			mountOnEnter
			unmountOnExit
		>
			<div
				role="presentation"
				tabIndex={-1}
				className={css.overlay}
				onClick={toolbarStore.closeMenu.bind(toolbarStore)}
				ref={overlayRef}
			/>
		</CSSTransition>
	);
});
