import React from 'react';
import { observer } from 'mobx-react';
import cn from 'classnames';

export const ShoppingTagIcon = observer(({ isSubmenuOpen }: { isSubmenuOpen?: boolean }) => {
	const style: React.CSSProperties = {
		strokeMiterlimit: 10
	};
	// 24x24
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width="24" viewBox="0 0 21.88 21.93">
			<path
				d="M12.3 21.23 1.54 10.47l-1-4.79L5.67.55l4.75 1.04 10.75 10.76-8.87 8.88Z"
				style={style}
				className={cn({
					'group-focus:tw-fill-white group-hover:tw-fill-white': !isSubmenuOpen,
					'tw-fill-white tw-stroke-navy-100 group-hover:tw-fill-navy-100': isSubmenuOpen,
				})}
			/>
			<circle
				cx="5.21"
				cy="5.27"
				r="1.25"
				style={style}
				className={cn({
					'group-focus:tw-stroke-navy-100 group-hover:tw-stroke-navy-100': !isSubmenuOpen,
					'tw-stroke-navy-100 group-hover:tw-stroke-white': isSubmenuOpen,
				})}
			/>
		</svg>
	);
});
