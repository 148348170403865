import React from 'react';
import { observer } from 'mobx-react';
import cn from 'classnames';

import css from '~/engage/toolbar/Components/engage-toolbar.module.scss';

type Props = {
	initials: string
	isSubmenuOpen?: boolean
};

export const ProfileIcon = observer(({ initials = '', isSubmenuOpen = false }: Props) => {
	return (
		<div
			className={
				cn(`
					tw-rounded-full tw-border tw-border-white group-hover:tw-bg-white group-hover:tw-text-navy-100
					tw-w-full tw-h-full tw-font-bold tw-flex tw-flex-col tw-items-center tw-justify-center
					tw-select-none
				`, {
					'tw-text-white': !isSubmenuOpen,
					'tw-bg-white tw-text-gray': isSubmenuOpen,
				})
			}
		>
			<span className={css.circularFlyoutMenuText}>{initials}</span>
		</div>
	);
});
