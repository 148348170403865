import React from 'react';

export const OnlineAccessSvg = () => {
	// 21x20
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width="21" viewBox="0 0 22.65 21.64">
			<path
				d="M5.57 6.26C5.57 3.08 8.15.5 11.33.5s5.76 2.58 5.76 5.76-2.58 5.76-5.76 5.76-5.76-2.58-5.76-5.76ZM22.14 17.9v3.24H.5V17.9c0-2.53 3.73-4.91 5.66-4.91 1.57.88 3.35 1.33 5.16 1.3 1.81.03 3.59-.42 5.17-1.3 1.45 0 5.66 2.6 5.66 4.91h-.01Z"
				vectorEffect="non-scaling-stroke"
			/>
		</svg>
	);
};
