import React from 'react';
import { observer } from 'mobx-react';

import { AsyncFormSubmitLoading } from '~/util/formz/Components/plugins/async-form-submit/AsyncFormSubmitLoading';
import { SelectField } from '~/util/formz/Components/fields/SelectField';
import { useSafeSpread } from '~/hooks/useSafeSpread';

export const IncognitoLocation = observer(({ store }) => {
	const { form } = store;
	const [formProps, formKey] = useSafeSpread(form.reactProps || {});

	return (
		<div className="ajax-submit-wrapper tw-relative">
			<AsyncFormSubmitLoading form={form} />
			<form key={formKey} {...formProps}>
				<SelectField field={form.fields.location}>
					{
						store?.locations?.map?.((loc, i) => (
							<option value={loc.name} key={loc.name + i}>{loc.name}</option>
						))
					}
				</SelectField>
			</form>
		</div>
	);
});
