import React from 'react';

export const DeliveryTruckSvg = () => {
	// 40x20
	return (
		<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 21">
			<path
				d="M.5,17.8V2.35c0-1.07.81-1.85,1.9-1.85h25.08v17.3H.5Z"
				vectorEffect="non-scaling-stroke"
			/>
			<path
				d="M4.81,17.75c0-1.52,1.26-2.75,2.82-2.75s2.82,1.23,2.82,2.75-1.26,2.75-2.82,2.75-2.82-1.23-2.82-2.75Z"
				vectorEffect="non-scaling-stroke"
				data-fill
			/>
			<path
				d="M27.65,4.6h6.5l5.35,6.8v6.4h-12.02l.17-13.2Z"
				vectorEffect="non-scaling-stroke"
			/>
			<path
				d="M28.86,17.75c0-1.52,1.26-2.75,2.82-2.75s2.82,1.23,2.82,2.75-1.26,2.75-2.82,2.75-2.82-1.23-2.82-2.75Z"
				vectorEffect="non-scaling-stroke"
				data-fill
			/>
		</svg>
	);
};
