import React from 'react';
import { observer } from 'mobx-react';
import cn from 'classnames';

export const QuickLinksIcon = observer(({ isSubmenuOpen }: { isSubmenuOpen?: boolean }) => {
	// 22x26
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width="22" viewBox="0 0 21.95 27.1">
			<path
				d="m10.98 1.55-10 13.9h10v10.11l10-13.89h-10V1.55Z"
				vectorEffect="non-scaling-stroke"
				className={cn({
					'group-focus:tw-fill-white group-hover:tw-fill-white': !isSubmenuOpen,
					[`
						tw-fill-white tw-stroke-navy-100 group-hover:tw-fill-navy-100 group-hover:tw-stroke-navy-100
					`]: isSubmenuOpen,
				})}
			/>
		</svg>
	);
});
