import React, { type MouseEventHandler } from 'react';
import { observer } from 'mobx-react';

import { type IncognitoLocationStore } from '~/engage/toolbar/incognito-location/Stores/IncognitoLocation.store';
import { IncognitoLocation } from '~/engage/toolbar/incognito-location/Components/IncognitoLocation';

type Props = {
	incognitoMode?: boolean
	incognitoLocation?: IncognitoLocationStore
	logout?: MouseEventHandler<HTMLButtonElement>
	name: string
	storeName: string
};

export const ProfileMenu = observer(({
	incognitoMode = false, incognitoLocation, logout, name, storeName = '',
}: Props) => {
	return (
		<div className="tw-space-y-4 tw-text-base eng:tw-text-sm">
			<div>
				<div className="tw-font-bold tw-break-all tw-break-words" data-qa="engage-profile-name">{name}</div>
				{
					incognitoMode &&
					<div className="tw-min-h-[27px]">
						{
							Boolean(incognitoLocation?.locations?.length) &&
							<IncognitoLocation store={incognitoLocation} />
						}
					</div>
				}
				{
					!incognitoMode && Boolean(storeName) &&
					<div>{storeName}</div>
				}
			</div>
			{
				logout !== undefined &&
				<div>
					<button
						data-qa="engage-profile-sign-out-button"
						className="ButtonAnchor tw-text-base eng:tw-text-sm"
						onClick={logout}
					>
						Sign out
					</button>
				</div>
			}
		</div>
	);
});
