import React from 'react';
import { observer } from 'mobx-react';
import cn from 'classnames';

import { type MenuViewKey } from '~/engage/toolbar/Stores/EngageToolbar.store';
import { noop } from '~/util/noop';
import css from '~/engage/toolbar/Components/engage-toolbar.module.scss';

export type ActiveEffect = 'STROKE' | 'FILL' | 'ALL' | 'CUSTOM';

type Props = {
	name: MenuViewKey
	className?: string
	onClick: React.MouseEventHandler<HTMLButtonElement>
	paddingX?: string
	Icon: React.FunctionComponent<{ isSubmenuOpen?: boolean }>
	open?: boolean
};

export const EngageToolbarMenuItem = observer(({
	paddingX = '0', onClick = noop, className = '', Icon, name, open = false,
}: Props) => {
	// Account for 3px border when submenu is open.
	const padding = open ? `calc(${paddingX} - 3px)` : paddingX;

	return (
		<button
			data-qa={`engage-menu-item-${name}`}
			className={cn(css.menuItem, 'tw-group', {
				[className]: Boolean(className),
				[css.isSubmenuOpen]: open,
			})}
			onClick={onClick}
			style={{
				paddingLeft: padding,
				paddingRight: padding,
			}}
		>
			<Icon isSubmenuOpen={open} />
		</button>
	);
});
