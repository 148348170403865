import React from 'react';

type Props = {
	color?: string
	width?: string
	height?: string
	className?: string
};

export const BusinessCustomerIcon = (props: Props) => {
	const {
		color = '#333',
		width = props.height ? '' : '20', //only include default if no height specified
		height = props.width ? '' : '17',
		className = '',
	} = props;
	const style: React.CSSProperties = {
		fill: 'none',
		stroke: color,
	};
	const style2: React.CSSProperties = {
		fill: color,
	};

	return (
		//customer-business.svg
		<svg xmlns="http://www.w3.org/2000/svg"
			{...{
				...(width && { width }),
				...(height && { height })
			}}
			viewBox="0 0 20 17" className={className}>

			<path
				d="M2.8 15.1V1h9v5.3"
				style={style}
				vectorEffect="non-scaling-stroke"
			/>
			<path
				d="M8.2 6.3h9v8.8h-9V6.3z"
				style={style}
				vectorEffect="non-scaling-stroke"
			/>
			<path
				d="M13.6 8.1h1.8v1.8h-1.8V8.1zm-3.6 0h1.8v1.8H10V8.1zm3.6 3.5h1.8v1.8h-1.8v-1.8zm-3.6 0h1.8v1.8H10v-1.8zM4.6 2.8h1.8v1.8H4.6V2.8zm3.6 0H10v1.8H8.2V2.8zM4.6 6.3h1.8v1.8H4.6V6.3zm0 3.5h1.8v5.3H4.6V9.8z"
				style={style2}
				vectorEffect="non-scaling-stroke"
			/>
			<path
				d="M1 15.1h18"
				style={style}
				vectorEffect="non-scaling-stroke"
			/>
		</svg>
	);
};
