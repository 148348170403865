import React from 'react';

export const CheckmarkSvg = () => {
	// 13x10
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width="13" height="10" version="1.2">
			<path
				d="m6.894 62.438 2.872 2.812 6.128-6"
				style={{
					stroke: 'currentcolor',
					strokeWidth: 2,
					strokeLinecap: 'butt',
					strokeLinejoin: 'miter',
				}}
				transform="translate(-4.894 -57.25)"
				vectorEffect="non-scaling-stroke"
			/>
		</svg>
	);
};
